import { createStore } from 'vuex'
import axios from 'axios'

// local
// const port = 8090
// const hostadress = 'http://localhost:' + port

// Test Server
//const hostadress = 'https://opac02.bfp-ausbildung.de:' + port

// Prod
const port = 443
const hostadress = 'https://opac-api.bfp-ausbildung.de:' + port
export default createStore({
  // global store for vue application
  state: {
    results: [],
    keywords: [],
    loading: false,
  },
  // mutations are methods that change data in the state
  // only synchrone code
  mutations: {
    setResult(state, results) {
      state.results = results;
    },
    setKeywords(state, keywords) {
      state.keywords = keywords;
    },
    toggleLoading(state) {
      state.loading = !state.loading;
    },
  },
  // methods to dispatch (call) asynchron api - cant change state data
  // has to commit mutations  
  actions: {
    async searchByParams({commit}, params) {
      if (params) {
        commit('toggleLoading');
        const response = await axios.post(hostadress + '/search', params)
        //console.log("call search", response);
        if (response.status == 200) {
          //console.log("call search DATA", response.data);
          setTimeout(() => {
            //console.log("1 Sek Loading", this.state.loading)
            commit('toggleLoading');
            commit('setResult', response.data);
          }, 1000)
          
        }
      }
    },
    async mediaById({commit}, params) {
      if (params) {
        //http://localhost:8090/media/181145
        commit('toggleLoading');
        const response = await axios.post(hostadress + '/mediaById', params)
        //console.log("call mediaById", response);
        if (response.status == 200) {
          //console.log("call mediaById DATA", response.data);
          commit('setResult', response.data);
          commit('toggleLoading');
        }
      }
    },
    async getKeywords({commit}) {
      console.log("/getKeywords");
      commit('toggleLoading');
      const response = await axios.get(hostadress + '/keywords')
      //console.log("call keywords", response);
      if (response.status == 200) {
        //console.log("call Keyword DATA", response.data);
        commit('setKeywords', response.data);
        commit('toggleLoading');
      }
    },
  },
  // filter data before make them availabel in the app
  getters: {
    showResults(state) {
      return !state.keywords.length && state.results && state.results.length >= 1;
    },
    showKeywords(state) {
      return !state.results.length && state.keywords && state.keywords.length >= 1;
    }
  },
  // can break up vuex store for different components
  // not needed here yet 
  modules: {
  }
})
